<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 10:41:21
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 13:57:57
-->
<template>
  <div class="nav">
    <header>
      <upload-pic :head="true" class="img" :initImg="user.headshot" @setImageUrl="image = $event"></upload-pic>
      <div class="info">
        <div class="name">
          {{user.name}}
          <span class="icon_gender" :class="{man:user.gender,woman:!user.gender}"></span>
        </div>
        <div class="tel">{{user.mobile}}</div>
      </div>
    </header>
    <div class="nav-list">
      <router-link to="/userCenter/index">
        <span class="icon_nav icon_1"></span>基本信息
      </router-link>
      <!-- <router-link to="/userCenter/index">
        <span class="icon_nav icon_2"></span>我的银行卡
      </router-link>-->
      <router-link to="/userCenter/myIndividual" v-if="showIndividual">
        <span class="icon_nav icon_2"></span>我的个体户
      </router-link>
      <router-link to="/userCenter/contracts">
        <span class="icon_nav icon_3"></span>我的协议
      </router-link>
      <router-link to="/userCenter/tasksList">
        <span class="icon_nav icon_4"></span>我的任务
      </router-link>
      <router-link to="/userCenter/editPass">
        <span class="icon_nav icon_5"></span>修改密码
      </router-link>
      <router-link to="/userCenter/messageList">
        <span class="icon_nav icon_6"></span>帮助中心
      </router-link>
      <a href="javascript:void(0);" @click="easemobim.bind()">
        <span class="icon_nav icon_7"></span>咨询客服
      </a>
      <router-link to="/userCenter/privacy">
        <span class="icon_nav icon_3"></span>隐私政策
      </router-link>
    </div>

    <el-button type="primary" round @click="handleLogout">退出登录</el-button>
  </div>
</template>

<script>
import uploadPic from "@/components/uploadID/index.vue";
import api from "@/api/list";
import { mapGetters } from "vuex";
export default {
  components: {
    uploadPic,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      image: "",
      easemobim: undefined,
      showIndividual: false
    };
  },
  watch: {
    image: function (val) {
      api.setHeadshot({ url: val }, { method: "patch" }).then((res) => {
        if (res.status == "200") {
          this.$message({
            showClose: true,
            message: "头像上传成功",
            type: "success",
          });
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    this.easemobim = window.easemobim || {};
    this.easemobim.config = {
      hide: true,
      //autoConnect: true,
      configId: "aaab0cb4-4a17-478b-9ab7-e90e515fbb15",
      dialogPosition: { x: "100px", y: "10px" },
    };
    this.getRelationIndividualOcompany();
  },
  methods: {
    getRelationIndividualOcompany(){
       api.getRelationIndividualOcompany()
      .then((res) => {
        console.log(res);
        if(res.status == 200&&res.data){
          this.showIndividual = true
        }
      });
    },
    handleLogout() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("FedLogOut").then(() => {
            this.$router.push({ path: "/login" });
            setTimeout(function () {
              location.reload();
            }, 500);
          });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消退出操作",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  width: 292px;
  height: 750px;
  background: #fff;
  border-radius: 6px;

  header {
    padding: 36px 10px 20px 36px;
    color: #333;
    overflow: hidden;
    .img {
      float: left;
    }

    .info {
      margin-left: 100px;
    }
    .name {
      font-size: 26px;
      line-height: 54px;
    }

    .tel {
      font-size: 14px;
    }
  }
  /deep/ .el-button--primary {
    display: block;
    padding: 9px 23px;
    width: 138px;
    margin: 100px auto 0;
  }

  .nav-list {
    /deep/ a {
      display: block;
      font-size: 14px;
      color: #333;
      line-height: 32px;
      margin-top: 20px;
      padding-left: 64px;
      text-decoration: none;
      cursor: pointer;
      .icon_nav {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 34px;
        margin-top: -2px;

        &.icon_1 {
          background-image: url(./assets/icon_1.png);
        }
        &.icon_2 {
          background-image: url(./assets/icon_2.png);
        }
        &.icon_3 {
          background-image: url(./assets/icon_3.png);
        }
        &.icon_4 {
          background-image: url(./assets/icon_4.png);
        }
        &.icon_5 {
          background-image: url(./assets/icon_5.png);
        }
        &.icon_6 {
          background-image: url(./assets/icon_6.png);
        }
        &.icon_7 {
          background-image: url(./assets/icon_7.png);
        }
      }

      &:hover {
        background: #e0e4fe;
      }

      &.router-link-exact-active {
        background: #e0e4fe;
        .icon_nav {
          &.icon_1 {
            background-image: url(./assets/icon_1_active.png);
          }
          &.icon_2 {
            background-image: url(./assets/icon_2_active.png);
          }
          &.icon_3 {
            background-image: url(./assets/icon_3_active.png);
          }
          &.icon_4 {
            background-image: url(./assets/icon_4_active.png);
          }
          &.icon_5 {
            background-image: url(./assets/icon_5_active.png);
          }
          &.icon_6 {
            background-image: url(./assets/icon_6_active.png);
          }
          &.icon_7 {
            background-image: url(./assets/icon_7_active.png);
          }
        }
      }
    }
  }
}
</style>