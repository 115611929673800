<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-17 15:38:52
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-23 14:03:55
-->
<template>
  <el-row class="userCenter">
    <el-col :span="6">
      <userCenterNav />
    </el-col>
    <el-col :span="18">
      <router-view :key="key" />
    </el-col>
  </el-row>
</template>
<script>
import userCenterNav from "./components/userCenterNav.vue";
export default {
  components: {
    userCenterNav,
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    },
  },
};
</script>

<style lang="less" scoped>
.userCenter {
  width: 1210px;
  margin: 0 auto;
  padding: 36px 0 20px;
}
</style>
