<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-14 09:43:12
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-08-29 13:11:41
-->
<template>
  <div>
    <el-upload
      class="uploader_section"
      :class="{front:front,back:back,head:head}"
      :action="action"
      accept="image/*"
      :limit="limit"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="img" />
      <span class="btn_center">上传</span>
    </el-upload>
  </div>
</template>

<script>
import { apiList } from "@/api/list";

export default {
  name: "uploadPic",
  props: {
    headers: {
      type: Object,
      default: function () {
        return {
          Authorization: this.$store.getters.token,
        };
      },
    },
    limit: {
      type: Number,
      default: 1,
    },
    front: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    head: {
      type: Boolean,
      default: false,
    },
    initImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  computed: {
    action: function () {
      let subString = this.head ? "employee-headshot" : "pcUploader";
      return process.env.VUE_APP_BASE_API + apiList.uploadUrl + subString;
    },
  },
  watch: {
    initImg: {
      handler: function (v) {
        this.imageUrl = v;
      },
      immediate: true,
    },
  },
  methods: {
    handleSuccess(response, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.head
        ? this.$emit("setImageUrl", response.data)
        : this.$emit("setImageUrl", file.raw);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: "上传图片大小不能超过2MB!",
          type: "error",
        });
      }

      return isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.uploader_section {
  position: relative;
  width: 238px;
  height: 136px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ebecf4;
  background-size: 184px 114px;
  border-radius: 10px;

  &.front {
    background-image: url(./assets/bg_front.png);
  }

  &.back {
    background-image: url(./assets/bg_back.png);
  }

  &.head {
    width: 82px;
    height: 82px;
    border-radius: 100%;
    background-image: url(./assets/user_bg.png);
    background-size: contain;

    .btn_center {
      width: 26px;
      height: 26px;
      background: url(./assets/icon_camera.png) center center no-repeat;
      color: transparent;
      right: 0;
      bottom: 0;
      margin-top: 14px;
      margin-left: 14px;
      border-radius: 0;
    }

    img {
      width: 76px;
      height: 76px;
      border-radius: 100%;
      margin: 3px;
      background: #fff;
    }
  }

  .btn_center {
    display: block;
    width: 40px;
    height: 40px;
    background: rgba(132, 147, 250, 0.8);
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
    z-index: 999;
  }

  img {
    width: 230px;
    height: 128px;
    border-radius: 4px;
    object-fit: cover;
    margin: 4px;
  }
}
</style>
